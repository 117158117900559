import { useEffect } from "react";
import { Link } from "react-router-dom";

function Widget() {
  useEffect(() => {
    const captivatedUser = localStorage.getItem("captivated-user");
    const clearCacheFlag = localStorage.getItem("captivated-clear-cache-flag");
    if (captivatedUser && !clearCacheFlag) {
      localStorage.removeItem("captivated-user");
      localStorage.setItem("captivated-clear-cache-flag", true);
      window.location.reload();
    }
  }, []);

  return (
    <>
      <div className="text-center py-20">
        <div className="text-white text-left inline-block max-w-xl m-auto">
          <h1 className="text-5xl font-bold tracking-tight">Widget Demo</h1>
          <div className="mt-10">
            <div className="space-y-5">
              {/* <div className="mt-5 bg-white shadow-lg rounded-lg p-5 flex items-center text-left text-black">
                <img className="w-14 rounded-full" src={captivatedUser.picture} alt="random user picture" />
                <div className="ml-5">
                  <h3 className="text-xl font-bold tracking-tight">{captivatedUser.name}</h3>
                  <p className="mt-1 text-lg">{captivatedUser.email}</p>
                </div>
              </div> */}
              <div className="mt-5 bg-white shadow-lg rounded-lg p-5 text-left text-black">
                <h3 className="text-xl font-bold tracking-tight">Overview</h3>
                <p className="mt-1 text-lg">Any feedback you give through the widget will be displayed on the <Link to="/dashboard"><span className="underline text-blue-500">founder's dashboard</span></Link>!</p>
                <p className="mt-5 text-lg">You can also send responses through the widget if you reply to a message through the founder's dashboard. You'll be prompted with notifications once you do!</p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
}

export default Widget;
