import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Home from "./Pages/Home";
import Widget from "./Pages/Widget";
import Dashboard from "./Pages/Dashboard";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={<Home/>}
        />
        <Route
          path="/widget"
          element={<Widget/>}
        />
        <Route
          path="/dashboard"
          element={<Dashboard/>}
        />
      </Routes>
    </Router>
  );
}

export default App;
