import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="text-center py-20">
      <div className="text-white text-left inline-block max-w-xl m-auto">
        <h1 className="text-5xl font-bold tracking-tight">Captivated Demo</h1>
        <p className="mt-5 text-xl">Pick a feature to explore</p>
        <div className="mt-10 flex items-center space-x-5 text-black">
          <Link to="/widget">
            <div className="bg-white rounded-md shadow-lg p-5 flex">
              <img className="w-14" src="https://app.usecaptivated.com/assets/icon.svg" alt="Captivated Icon"/>
              <div className="ml-4">
                <h3 className="text-2xl font-bold tracking-tight">Widget</h3>
                <p className="mt-2 text-lg">See how your users give you feedback</p>
              </div>
            </div>
          </Link>
          <Link to="/dashboard">
            <div className="bg-white rounded-md shadow-lg p-5 flex">
              <img style={{ fill: "#0070F4" }} className="w-14" src="/dashboard.svg" alt="Dashboard Icon"/>
              <div className="ml-4">
                <h3 className="text-2xl font-bold tracking-tight">Dashboard</h3>
                <p className="mt-2 text-lg">View and understand your feedback</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Home;