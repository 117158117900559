import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Dashboard() {

  return (
    <>
      <div className="text-center py-20">
        <div className="text-white text-left inline-block max-w-xl m-auto">
          <h1 className="text-5xl font-bold tracking-tight">Founder's Dashboard</h1>
          <div className="mt-10">
            <div className="space-y-5">
              <div className="bg-white shadow-lg rounded-lg p-5 text-left text-black">
                <h3 className="text-xl font-bold tracking-tight">Overview</h3>
                <p className="mt-1 text-lg">Log into a demo account on the founder's dashboard and see for yourself what the product is like. Any feedback you submitted through the demo widget will be available on the demo dashboard as well.</p>
                <p className="mt-5 text-lg">You can also reply to specific pieces of eedback. If you respond to your own piece of feedback, you'll be able to see it on the <Link to="/widget"><span className="underline text-blue-500">widget demo</span></Link> upon refresh.</p>
              </div>
              <div className="bg-white shadow-lg rounded-lg p-5 text-left text-black">
                <p><strong>Use the link and credentials below to access the demo account.</strong></p>
                <p className="mt-2"><strong>Email:</strong> demo@usecaptivated.com</p>
                <p className="mt-2"><strong>Password:</strong> captivated123</p>
                <p className="mt-2"><strong>Website:</strong> <a className="text-blue-500 underline" href="https://app.usecaptivated.com" target="_blank" rel="noreferrer">https://app.usecaptivated.com</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
